body {
  text-rendering: geometricPrecision;
}

.site-sans-serif {
  font-family: -apple-system, BlinkMacSystemFont, 'helvetica neue', helvetica, ubuntu, roboto, noto, 'segoe ui', arial, sans-serif;
}
.site-tracking {
  letter-spacing: -0.02em;
}

abbr {
  font-size: 91.66666667%;
  letter-spacing: -0.015rem;
}
abbr[title] {
  text-decoration-color: rgba(0, 0, 0, 0.8);
  text-decoration-style: dotted;
}

#app {
  position: relative;
}

@media screen and (min-width: 30em) {
  aside {
    margin-top: -5rem !important;
  }
}

.results-listing:before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: linear-gradient(rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 1));
}

input[type="search"] {
  border-radius: 6px;
}
input[type="search"]:focus {
  outline: none;
  border-color: rgb(53, 126, 221, 0.75);
  box-shadow: 0 0 8px rgb(53, 126, 221, 0.5);
}

/* optical alignment */
@media screen and (max-width: 30em) {
  .midi-pill {
    margin-left: -2px;
  }
}
@media screen and (min-width: 30em) {
  .midi-pill {
    margin-top: -3px;
  }
}
