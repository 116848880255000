body {
  text-rendering: geometricprecision;
}

.site-sans-serif {
  font-family: -apple-system, BlinkMacSystemFont, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
}

.site-tracking {
  letter-spacing: -.02em;
}

abbr {
  letter-spacing: -.015rem;
  font-size: 91.6667%;
}

abbr[title] {
  text-decoration-style: dotted;
  text-decoration-color: #000c;
}

#app {
  position: relative;
}

@media screen and (width >= 30em) {
  aside {
    margin-top: -5rem !important;
  }
}

.results-listing:before {
  content: " ";
  pointer-events: none;
  background: linear-gradient(#fff0 50%, #fff);
  width: 100%;
  height: 100%;
  position: absolute;
}

input[type="search"] {
  border-radius: 6px;
}

input[type="search"]:focus {
  border-color: #357eddbf;
  outline: none;
  box-shadow: 0 0 8px #357edd80;
}

@media screen and (width <= 30em) {
  .midi-pill {
    margin-left: -2px;
  }
}

@media screen and (width >= 30em) {
  .midi-pill {
    margin-top: -3px;
  }
}

/*# sourceMappingURL=index.a4e0c04d.css.map */
